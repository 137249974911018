var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-12 col-md-12 col-sm-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "설비 목록",
              columns: _vm.grid.columns,
              gridHeight: _vm.grid.height,
              data: _vm.grid.data,
              filtering: false,
              columnSetting: false,
              usePaging: false,
              editable: _vm.editable,
              rowKey: "equipmentCd",
            },
            on: { rowClick: _vm.rowClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            { attrs: { title: "설비별 점검결과 상세" } },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "설비별 점검결과 저장", icon: "save" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "설비별 결과 기본정보", bgClass: "" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "사업장/점검부서",
                                  value:
                                    _vm.result.plantName +
                                    "/" +
                                    _vm.result.deptNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "설비유형/설비코드/설비명",
                                  value:
                                    _vm.result.equipmentType +
                                    "/" +
                                    _vm.result.equipmentCd +
                                    "/" +
                                    _vm.result.equipmentNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검유형",
                                  value: _vm.result.safFacilityNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검자",
                                  value: _vm.result.chkUserNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검완료일",
                                  value: _vm.result.chkSchCompleYmd,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검결과",
                                  value: _vm.result.chkResultNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검완료여부",
                                  value: _vm.result.chkRsltYnNm,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-label-text", {
                                attrs: {
                                  title: "점검결과요약",
                                  value: _vm.result.chkRsltSmry,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-table", {
                          ref: "table",
                          attrs: {
                            title: "설비별 점검항목",
                            columns: _vm.grid2.columns,
                            gridHeight: _vm.grid2.height,
                            data: _vm.grid2.data,
                            filtering: false,
                            columnSetting: false,
                            usePaging: false,
                            hideBottom: true,
                            noDataLabel: "설비에 대한 점검항목이 없습니다.",
                            editable: _vm.editable && !_vm.param.disabled,
                            rowKey: "equipmentCd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }